import React from 'react';

import { Metadata } from '@latitude/metadata';
import { SecondaryHero } from '@latitude/hero';

import Layout from '@/components/layout';
import { SITE_URL, TITLE_SUFFIX } from '@/utils/constants';
import VisaOffers from './_visa-offers';

function GemVisaOffersPage({ location }) {
  return (
    <Layout location={location}>
      <Metadata
        title={`Visa offers | ${TITLE_SUFFIX}`}
        description="Enjoy exclusive deals, discounts and offers by simply using your Visa card at home and overseas."
        keywords="visa offers"
        canonical={`${SITE_URL}${location.pathname}`}
      >
        <link
          type="text/css"
          rel="stylesheet"
          href="https://offerswidget.visa.com/vos/styles/syndication-style.css"
        />
        <link
          type="text/css"
          rel="stylesheet"
          href="https://offerswidget.visa.com/vos/styles/syndication/latitude-au-syndication-style.css"
        />
      </Metadata>
      <SecondaryHero heading="Visa offers" />
      <VisaOffers />
    </Layout>
  );
}

export default GemVisaOffersPage;
